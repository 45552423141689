import { defineStore } from 'pinia';
import JwtService from '@/core/services/JwtService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import ApiService from '@/core/services/ApiService';
import CryptoJS from 'crypto-js';

export interface User {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  api_token: string;
  locale: string;
  avatar: string;
  iss: string;
  aud: string;
  company: CompanyDetails;
}

export interface CompanyDetails {
  name: string;
  address: CompanyAddress;
}

export interface CompanyAddress {
  address1?: string;
  address2?: string;
  city?: string;
  postcode?: string;
  state?: string;
  country: string;
}

export interface UtbmsActivity {
  id?: string;
  description?: string;
  expected_code?: string;
  activity_code?: string;
  inhouse_code?: string;
  inhouse_probability?: string;
  gpt_code?: string;
  gpt_probability?: string;
}

export interface UtbmsResponse {
  code?: string;
  text?: string;
  probability_percent?: string;
}

export const piniaUserStore = defineStore({
  id: 'user-store',
  state: () => ({
    user: {} as User,
    isAuthenticated: !!JwtService.getToken(),
    inhouseAiRes: {} as UtbmsResponse,
    gptAiRes: {} as UtbmsResponse,
    errors: {},
    message: '',
    locale: '',
  }),
  getters: {
    currentUser(state): User {
      return state.user;
    },
    isUserAuthenticated(state): boolean {
      return state.isAuthenticated;
    },
    getErrors: (state) => state.errors,
    getMessage: (state) => state.message,
    getUserLanguage: (state) => state.locale,
  },
  actions: {
    [Actions.LOGIN](credentials) {

      return ApiService.post('auth/login', credentials)
        .then(({ data }) => {
          this[Mutations.SET_AUTH](data);
        })
        .catch(({ response }) => {
          if (response !== undefined) {
            const errors = response.data.errors
              ? response.data.errors
              : [response.data.message];
            this[Mutations.SET_ERROR](errors);
          }
        });
    },
    [Actions.PASSWORD](credentials) {

      return ApiService.post('user/password', credentials)
        .then(({ data }) => {
          this[Mutations.SET_AUTH](data);
        })
        .catch(({ response }) => {
          if (response !== undefined) {
            const errors = response.data.errors
              ? response.data.errors
              : [response.data.message];
            this[Mutations.SET_ERROR](errors);
          }
        });
    },
    [Actions.AUTO_LOGIN](user) {
      this[Mutations.SET_AUTH](user);
    },
    async [Actions.LOGOUT]() {
      this[Mutations.PURGE_AUTH]();
    },
    [Actions.REGISTER](credentials) {

      return ApiService.post('user', credentials)
        .then(({ data }) => {
          this[Mutations.SET_MESSAGE](data);
        })
        .catch(({ response }) => {
          if (response !== undefined)
            this[Mutations.SET_ERROR](response.data.errors);
        });
    },
    [Actions.FORGOT_PASSWORD](payload) {

      return ApiService.post('user/reset-password', payload)
        .then(() => {
          this[Mutations.SET_ERROR]({});
        })
        .catch(({ response }) => {
          if (response !== undefined)
            this[Mutations.SET_ERROR](response.data.errors);
        });
    },
    [Actions.GET_PROFILE](credentials) {

      return ApiService.get('user', `?email=${credentials.email}`)
        .then(({ data }) => {
          this[Mutations.SET_PROFILE](data);
        })
        .catch(({ response }) => {
          if (response !== undefined)
            this[Mutations.SET_ERROR](response.data.errors);
        });
    },
    [Actions.SET_SESSION_AUTH](userPayload) {
      delete userPayload.company;
      // delete userPayload.locale;
      // delete userPayload.phone;

      const secret = JwtService.getPassphrase();
      const encryptionIv = JwtService.getEncryptionIv();

      const jwt = CryptoJS.AES.encrypt(JSON.stringify(userPayload), secret, {
        iv: encryptionIv,
        mode: CryptoJS.mode.CBC,
      });

      JwtService.saveSessionToken(jwt.toString());
    },
    async [Actions.VERIFY_AUTH](token: { token: string | null }) {

      const jwtToken = token.token;

      if (
        this.isUserAuthenticated &&
        jwtToken &&
        JwtService.getSessionToken() === jwtToken
      ) {
        const secret = JwtService.getPassphrase();
        const encryptionIv = JwtService.getEncryptionIv();

        const cipher = CryptoJS.AES.decrypt(jwtToken, secret, {
          iv: encryptionIv,
          mode: CryptoJS.mode.CBC,
        });

        const decryptedToken = CryptoJS.enc.Utf8.stringify(cipher).toString();

        if (decryptedToken) {
          if (JSON.parse(decryptedToken)) {
            const data = {
              data: {
                user: JSON.parse(decryptedToken),
                token: JwtService.getToken(),
              }
              , message: '',
            };
            this[Mutations.SET_AUTH](data);
          }
        } else {
          this[Mutations.PURGE_AUTH]();
        }
      } else {
        this[Mutations.PURGE_AUTH]();
      }
    },
    async [Actions.UPDATE_PROFILE](values) {
      return ApiService.put('user', values)
        .then(({ data }) => {
          this[Mutations.SET_PROFILE](data);
        })
        .catch(({ response }) => {
          if (response !== undefined)
            this[Mutations.SET_ERROR](response.data.errors);
        });
    },
    [Actions.TRIGGER_AI](reqObj) {

      return ApiService.get('utbms-code/activity', `?narrative=${reqObj.narrative}&use_gpt=${reqObj.use_gpt}`)
        .then(({ data }) => {
          this[Mutations.SET_UTBMS_CODE](reqObj, data);
        })
        .catch(({ response }) => {
          if (response !== undefined)
            this[Mutations.SET_ERROR](response.data.errors);
        });
    },
    /* Mutations from Vuex */
    [Mutations.SET_MESSAGE](data) {
      this.message = data.message;
    },
    [Mutations.SET_AUTH](data) {
      this.isAuthenticated = true;
      this.user = data.data.user;
      this.errors = {};

      this.message = data.message;

      this.user.iss = JwtService.getIssuer();
      this.user.aud = JwtService.getAudience();

      window.localStorage.setItem('lang', this.user.locale);
      this.locale = this.user.locale;

      JwtService.saveToken(data.data.token);
    },
    [Mutations.SET_USER](user) {
      this.user = user;
    },
    [Mutations.SET_PASSWORD](password) {
      this.user.password = password;
    },
    [Mutations.PURGE_AUTH]() {
      this.isAuthenticated = false;
      this.user = {} as User;
      this.errors = [];
      JwtService.destroyToken();
      JwtService.destroySessionToken();
    },
    [Mutations.SET_ERROR](error) {
      this.errors = { ...error };
    },
    [Mutations.SET_PROFILE](data) {
      this.message = data.message;
      this.user = data.data.user;
      const locale = this.user.locale;

      if (typeof locale !== 'undefined' && locale !== null) {
        window.localStorage.setItem('lang', locale);
        this.locale = locale;
      }

    },
    [Mutations.SET_UTBMS_CODE](reqObj, data) {
      if (reqObj.use_gpt > 0) {
        this.gptAiRes = data.data[0];
      } else {
        this.inhouseAiRes = data.data[0];
      }
    },
  },
});
