import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { piniaUserStore } from '@/store/user';
import { Actions } from '@/store/enums/StoreEnums';
import JwtService from '@/core/services/JwtService';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/invoices',
    component: () => import('@/layout/Layout.vue'),
    children: [

      {
        path: '/invoices',
        name: 'invoices',
        component: () => import('@/views/Invoices.vue'),
      },
      {
        path: '/enhancement',
        name: 'enhancement-invoices',
        component: () => import('@/views/Enhancements.vue'),
      },
      {
        path: '/invoice/:uuid/details',
        name: 'invoice-details',
        component: () => import('@/views/crafted/invoice-details/InvoiceDetails.vue'),
        children: [
          {
            path: '/invoice/:uuid/details/general',
            name: 'general',
            component: () => import('@/views/crafted/invoice-details/General.vue'),
          },
          {
            path: '/invoice/:uuid/details/timekeepers',
            name: 'timekeepers',
            component: () => import('@/views/crafted/invoice-details/TimekeeperRecords.vue'),
          },
          {
            path: '/invoice/:uuid/details/line-items',
            name: 'line-items',
            component: () => import('@/views/crafted/invoice-details/LineItems.vue'),
          },
        ],
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('@/views/crafted/account/Account.vue'),
        children: [
          {
            path: 'overview',
            name: 'account-overview',
            component: () => import('@/views/crafted/account/Overview.vue'),
          },
          {
            path: 'settings',
            name: 'account-settings',
            component: () => import('@/views/crafted/account/Settings.vue'),
          },
          {
            path: 'billing',
            name: 'account-billing',
            component: () => import('@/views/crafted/account/Billing.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/components/page-layouts/Auth.vue'),
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignIn.vue'),
      },
      {
        path: '/sign-up',
        name: 'sign-up',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignUp.vue'),
      },
      {
        path: '/password-reset',
        name: 'password-reset',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/PasswordReset.vue'),
      },
      {
        path: '/password',
        name: 'set-password',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SetPassword.vue'),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/crafted/authentication/Error404.vue'),
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/views/crafted/authentication/Error500.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
  {
    path: "/rocketspace",
    name: "rocketspace",
    component: () => import("@/views/crafted/hackathon/Rocketspace.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  const store = piniaUserStore();
  // reset config to initial state
  // TODO store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store[Actions.VERIFY_AUTH]({ token: JwtService.getSessionToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
