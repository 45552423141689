const language_de = {
  "dashboard": "Dashboard",
  "layoutBuilder": "Layout-Builder",
  "craft": "Hergestellt",
  "pages": "Seiten",
  "profile": "Profil",
  "profileOverview": "Überblick",
  "projects": "Projekte",
  "campaigns": "Kampagnen",
  "documents": "Unterlagen",
  "connections": "Anschlüsse",
  "wizards": "Zauberer",
  "horizontal": "Horizontal",
  "vertical": "Vertikal",
  "account": "Konto",
  "accountOverview": "Überblick",
  "settings": "Die Einstellungen",
  "authentication": "Authentifizierung",
  "basicFlow": "Grundfluss",
  "signIn": "Einloggen",
  "signUp": "Anmelden",
  "passwordReset": "Passwort zurücksetzen",
  "multiStepSignUp": "Multi-Steps-Anmeldung",
  "error404": "Fehler 404",
  "error500": "Fehler 500",
  "apps": "Apps",
  "chat": "Plaudern",
  "privateChat": "Private Chat",
  "groupChat": "Privater Chat",
  "drawerChat": "Gruppenchat Schubladen-Chat",
  "widgets": "Widgets",
  "widgetsLists": "Listen",
  "widgetsStatistics": "Statistiken",
  "widgetsCharts": "Diagramme",
  "widgetsMixed": "Gemischt",
  "widgetsTables": "Tabellen",
  "widgetsFeeds": "Einspeisungen",
  "changelog": "Änderungsprotokoll",
  "docsAndComponents": "Dokumente & Komponenten",
  "megaMenu": "Mega-Menü",
  "exampleLink": "Beispiellink",
  "modals": "Modale",
  "general": "Allgemeines",
  "inviteFriends": "Freunde Einladen",
  "viewUsers": "Benutzer Anzeigen.",
  "upgradePlan": "Upgrade-Plan",
  "shareAndEarn": "Teilen & Verdienen",
  "forms": "Formen",
  "newTarget": "Neues Ziel",
  "newCard": "Neue Karte",
  "newAddress": "Neue Adresse",
  "createAPIKey": "Api-Key Erstellen",
  "twoFactorAuth": "Zwei Faktor Auth.",
  "createApp": "App Erstellen",
  "createAccount": "Benutzerkonto Erstellen",
  "activity": "Aktivität",
  "documentation": "Dokumentation",
  "components": "Bauteile",
  "resources": "Ressourcen",
  "customers": "Kunden",
  "gettingStarted": "Einstieg",
  "customersListing": "Kundenauflistung",
  "customerDetails": "Kundenangaben",
  "calendarApp": "Kalender",
  "subscriptions": "Abonnements",
  "getStarted": "Einstieg",
  "subscriptionList": "Abonnementliste",
  "addSubscription": "Subskription Hinzufügen.",
  "viewSubscription": "Abonnement Anzeigen.",
  /* extractSpace */
  "login_continue": "Weiter",
  "invoice_enhancement": "Rechnungserweiterung",
  "invoice_list": "Rechnungsliste",
  "subscription_plan": "Abonnement-Plan",
  "status_progress": "in Arbeit",
  "status_approval": "Zulassung erforderlich",
  "status_enhancement": "Erweiterungsbedarf",
  "status_completed": "herausgezogene",
  "label_subscription_plan": "Abonnement-Plan",
  "label_current_plan": "Aktueller Plan",
  "label_current_bill": "Aktuelle monatliche/jährliche Rechnung",
  "label_next_due_date": "Nächster Zahlungstermin",
  "label_included_invoices": "Beinhaltet Rechungen",
  "label_plan_upgrade": "Upgrade plan",
  "label_country": "Land",
  /* Account */
  "profile_update_success": "Das Profil wurde erfolgreich aktualisiert.",
  "form_label_company_name": "Unternehmen",
  "form_label_street": "Strasse",
  "form_label_house_number": "Hausnummer",
  "form_label_extra_address": "2. Adresse",
  "form_label_city": "Stadt",
  "form_label_zip": "PLZ",
  "form_label_state": "Staat/Provinz",
  "form_label_save_button": "Speichern",
  "form_label_discard_button": "Verwerfen",
  "label_use_billing_address": "Als Rechnungsadresse verwenden?",
  "label_please_wait": "Bitte warte...",
  "label_profile_details": "Profildetails",
  "label_profile_address": "Adresse",
  "label_profile_signin": "Anmeldeverfahren",
  "label_profile_email": "Email Adresse",
  "label_profile_password": "Passwort",
  "form_label_full_name": "Vor- und Nachname",
  "form_label_phone": "Telefonnummer",
  "form_label_language": "Sprache",
  "form_label_new_email_address": "Neue Email Adresse eingeben",
  "form_label_confirm_password": "Passwort bestätigen",
  "form_label_update_email_address": "Email Adresse aktualisieren",
  "form_label_change_email_address": "Email Adresse ändern",
  "form_label_password_validation_rules": "Das Passwort muss mindestens 8 Zeichen lang sein und Sonderzeichen enthalten.",
  "form_label_current_password": "Aktuelles Passwort",
  "form_label_new_password": "Neues Passwort",
  "form_label_confirm_new_password": "Neues Passwort bestätigen",
  "form_label_cancel": "Abbrechen",
  "form_label_reset_password": "Passwort zurücksetzen",

  /* Sign up-in */
  "label_language": "Sprache",
  "email_label": "Email",
  "password_label": "Paswort",
  "forgot_password_label": "Passwort vergessen?",
  "or_label": "Oder",
  "create_account_label": "Neuen Account anlegen",
  "already_account_label": "Account bereits vorhanden?",
  "sign_in_here": "Hier anmelden",
  "first_name": "Vorname",
  "last_name": "Nachname",
  "agree_label": "Ich bestätige &",
  "tnc_label": "Allgemeinen Geschäftsbedingungen",
  "password_setup": "Passworteinstellungen",
  "password_rules": "Verwenden Sie 8 oder mehr Zeichen mit einer Kombination aus Buchstaben, Zahlen &amp; Sonderzeichen.",
  /* Invoices */
  "upload_invoice": "Rechnung hochladen",
  "ready_for_ai": "Bereit für AI",
  "in_progress": "In Arbeit",
  "action_needed": "Bearbeitung erforderlich",
  "ready_to_submit": "Bereit für Bestätigung",
  "ready_for_ledes": "Bereit für LEDES",
  confirm_delete: "",
  confirm_submit: "",
  confirm_ledes: "",
  confirm_archive: "",
  invoice_last_pull: "",
  /* Success Error messages */
  "company.update.success": "Unternehmensangaben wurden erfolgreich aktualisiert!",
  "invoice.upload.success": "Rechnung wurde erfolgreich hochgeladen!",
  "invoice.extraction_request.success": "Extrahierung von Rechnungsdaten erfolgreich übermittelt!",
  "invoice.delete.success": "Rechnung erfolgreich gelöscht!",
  "auth.login.success": "Anmeldung erfolgreich!",
  "user.registration.success": "Benutzer erfolgreich registriert!",
  "passwords.set.success": "Passwort erfolgreich gespeichert!",
  "user.get.success": "Benutzerprofil erfolgreich ausgewählt!",
  "user.update.success": "Benutzerprofil erfolgreich aktualisiert!",
  "user.resend_verification.success": "Bestätigungslink erneut gesendet!",
  "passwords.init_reset.success": "Das Zurücksetzen des Passwortes war erfolgreich!",
};

export default language_de;
